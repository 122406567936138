import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Fab,
  Slide,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  DeletePlayDialog,
  EventCard,
  AddPlayButton,
} from 'shared/components';
import { DateTime, Interval } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useState } from 'react';

export function PlayCard(props) {
  const { t } = useTranslation();
  const [match] = useOutletContext();
  const navigate = useNavigate();
  const { play, cardSelectedId, handleSelectCard } = props;
  const [state, setState] = useState({
    openDeletePlayDialog: false,
  });

  const selected = cardSelectedId === play._id;
  let qb; let receiver;
  let formatedTime = '00 : 00 : 00';
  if (match && match?.time && play?.createdAt) {
    let startTime;
    if (play.match_time === 1) {
      startTime = DateTime.fromISO(match?.time?.first_time?.start);
    } else {
      startTime = DateTime.fromISO(match?.time?.second_time?.start);
    }
    if (play.editedOnTimeline) {
      formatedTime = t('Editado');
    } else {
      const interval = Interval.fromDateTimes(startTime, DateTime.fromISO(play?.createdAt));
      formatedTime = interval.toDuration(['hours', 'minutes', 'seconds']).toFormat('hh : mm : ss');
    }
  }

  if (play.qb) {
    const qbIndex = props.athletes.findIndex((x) => x._id === play.qb);
    if (qbIndex >= 0) qb = props.athletes[qbIndex];
  }

  if (play.receiver) {
    const receiverIndex = props.athletes.findIndex((x) => x._id === play.receiver);
    if (receiverIndex >= 0) receiver = props.athletes[receiverIndex];
  }

  let down = t('Descida não informada');
  if (play.is_extra_point) {
    down = '';
  } else if (play.down) {
    down = t(`${play.down}ª descida`);
  }

  let cardStyle;
  let cardIcon;
  let afterCard = (play.is_first_down) ? 'FIRST DOWN' : false;

  if (play.is_first_down) {
    afterCard = 'FIRST DOWN';
    cardIcon = 'ball';
    cardStyle = 'playcardFirst';
  }

  if (play.is_touchdown) {
    cardIcon = 'ball';
    if (play.defense) {
      cardStyle = (play.play_result === 'intercept') ? 'playcardTd' : 'playcardError';
      afterCard = (play.play_result === 'intercept') ? 'PICK SIX' : 'TOUCHDOWN';
    } else {
      cardStyle = (play.play_result === 'intercept') ? 'playcardError' : 'playcardTd';
      afterCard = (play.play_result === 'intercept') ? 'PICK SIX' : 'TOUCHDOWN';
    }
  }

  if (play.is_extra_point) {
    afterCard = (afterCard) ? afterCard.replace(/TOUCHDOWN/i, t('CONVERSÃO')) : afterCard;
    afterCard = (afterCard) ? afterCard.replace(/PICK SIX/i, t('TD DE DEFESA EM CONVERSÃO')) : afterCard;
    cardIcon = 'shoe';
  }

  if (play.is_first_down) {
    if (play.defense) {
      cardStyle = (play.play_result === 'intercept') ? '' : 'playcardWarning';
    } else {
      cardStyle = (play.play_result === 'intercept') ? 'playcardWarning' : '';
    }
  }

  function getLabelType(type) {
    switch (type) {
      case 'pass':
        return t('Passe');
      case 'run':
        return t('Corrida');
      default:
        return t('Tipo de jogada não informado');
    }
  }

  function getLabelResult(result) {
    switch (result) {
      case 'tackle':
        return t('Tackle');
      case 'drop':
        return t('Drop');
      case 'tip':
        return t('Tip');
      case 'wrong_pass':
        return t('Passe errado');
      case 'out':
        return t('Saída de campo');
      case 'touchdown':
        return t('Touchdown');
      case 'fault':
        return t('Falta');
      case 'intercept':
        return t('Interceptação');
      case 'sack':
        return t('Sack');
      default:
        return t('Resultado não informado');
    }
  }

  const toggleSelect = (event) => {
    event.stopPropagation();
    handleSelectCard(selected ? null : play._id);
  };

  const handleClickOpenDeletePlayDialog = () => {
    setState((prevState) => ({ ...prevState, openDeletePlayDialog: true }));
  };
  const handleCloseDeletePlayDialog = () => {
    setState((prevState) => ({ ...prevState, openDeletePlayDialog: false }));
  };

  function getTitle() {
    let title;
    if (play.is_extra_point) {
      title = 'Extra Point';
    } else if (down) {
      title = `${down} ${getLabelType(play.type)}`;
    } else {
      title = getLabelType(play.type);
    }

    return title;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        marginTop: selected && '70px',
        marginBottom: selected && '100px',
      }}
    >
      {selected && (
        <AddPlayButton insertPosition="before" selectedPlayId={play._id} />
      )}
      <Box className={`${play._id} playcard ignore-unselect-playCard ${play.offense ? 'play-offense' : 'play-defense'}`}>
        <Box
          className="play-timer"
          sx={{
            width: '120px',
            height: '32px',
            margin: '10px',
            background: '#EFF3F7',
            opacity: '0.64',
            borderRadius: '4px',
            boxSizing: 'border-box',
            padding: '5px 10px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Mulish',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '24px',
              color: '#50729A',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            {formatedTime}
          </Typography>
        </Box>
        <Box sx={{ position: 'relative' }}>
          <Card
            sx={{
              width: '184px',
              background: selected ? 'linear-gradient(137.13deg, #004D99 31.91%, #0080FF 140.67%);' : '#FFFFFF',
              boxShadow: '0px 8px 32px rgba(0, 102, 204, 0.16)',
              borderRadius: '4px',
              color: selected ? '#ffffff' : 'none',
              padding: '5px',
              transform: selected ? 'scale(107%)' : '',
              transition: 'transform 0.1s ease-in-out',
            }}
          >
            <CardActionArea onClick={toggleSelect} sx={{ opacity: play?.ignore_play ? '50%' : '' }}>
              <CardHeader
                sx={{
                  fontSize: '14px',
                  padding: '8px 16px',
                }}
                title={(
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontFamily: 'Mulish',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      fontSize: '14px',
                      lineHeight: '18px',
                      color: selected ? '#ffffff' : '#3E4A59',
                    }}
                  >
                    {getTitle()}
                  </Typography>
                )}
              />
              <CardContent
                sx={{
                  padding: '0 16px',
                }}
              >
                {qb
                  && (
                    <Typography variant="body2">
                      QB: #
                      {qb.number}
                      {' '}
                      {qb.name}
                    </Typography>
                  )}
                {receiver
                  && (
                    <Typography variant="body2">
                      {t('Recebedora')}
                      : #
                      {receiver.number}
                      {' '}
                      {receiver.name}
                    </Typography>
                  )}
                <hr style={{ border: 'none', borderTop: '1px solid #ececec' }} />
                <Typography variant="body2">
                  {play.play_result ? getLabelResult(play.play_result) : t('Resultado não informado')}
                  {play?.yards
                  && (
                    <small>
                      {' / '}
                      {play.yards}
                      {' '}
                      {t('jardas')}
                    </small>
                  )}
                </Typography>
              </CardContent>
            </CardActionArea>
            {play?.ignore_play
              && (
                <>
                  <hr style={{ border: 'none', borderTop: '1px solid #ececec' }} />
                  <Box
                    sx={{ padding: '0 16px' }}
                  >
                    <Typography
                      variant="body2"
                      align="center"
                    >
                      {t('Esta jogada teve falta e as estatísticas e pontuação foram desconsideradas.')}
                    </Typography>
                  </Box>
                </>
              )}
          </Card>
          <Slide direction={play.offense ? 'left' : 'right'} in={selected} mountOnEnter unmountOnExit>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                marginTop: '-26px',
                right: play.offense ? '-70%' : 'none',
                left: play.defense ? '-75%' : 'none',
              }}
            >
              <Fab
                color="secondary"
                onClick={() => navigate(`/matches/${match._id}/play/${cardSelectedId}`)}
                style={{
                  marginRight: '10px',
                }}
              >
                <EditIcon />
              </Fab>
              <Fab
                color="secondary"
                onClick={() => handleClickOpenDeletePlayDialog()}
              >
                <DeleteIcon />
              </Fab>
            </Box>
          </Slide>
        </Box>
      </Box>
      <DeletePlayDialog
        openDeletePlayDialog={state.openDeletePlayDialog}
        handleClose={handleCloseDeletePlayDialog}
        match={match}
        play={play}
        title={getTitle()}
      />
      {afterCard
        && !play?.ignore_play
        && <EventCard cardStyle={cardStyle} label={afterCard} icon={cardIcon} />}
      {selected
        && props.shouldShowAddPlayButton
        && (
          <AddPlayButton insertPosition="after" selectedPlayId={play._id} />
        )}
    </Box>
  );
}

export default PlayCard;
